import { default as indexBNnOS4J1c3Meta } from "/home/runner/work/rediredi-storefront/rediredi-storefront/pages/about/index.vue?macro=true";
import { default as index8tzUv43YSwMeta } from "/home/runner/work/rediredi-storefront/rediredi-storefront/pages/checkout/index.vue?macro=true";
import { default as _91id_93oziuXCTLdGMeta } from "/home/runner/work/rediredi-storefront/rediredi-storefront/pages/checkout/success/[identifier]/[id].vue?macro=true";
import { default as indexzKdeOdJNCkMeta } from "/home/runner/work/rediredi-storefront/rediredi-storefront/pages/index.vue?macro=true";
import { default as indexYP28gpEmqjMeta } from "/home/runner/work/rediredi-storefront/rediredi-storefront/pages/not-available/index.vue?macro=true";
import { default as _91token_933iprqfPdpkMeta } from "/home/runner/work/rediredi-storefront/rediredi-storefront/pages/orders/[token].vue?macro=true";
import { default as _91_91title_93_93q7Qokm9tqQMeta } from "/home/runner/work/rediredi-storefront/rediredi-storefront/pages/products/[id]/[[title]].vue?macro=true";
import { default as indexFxylc02rcBMeta } from "/home/runner/work/rediredi-storefront/rediredi-storefront/pages/products/index.vue?macro=true";
import { default as _91_91title_93_93QA9sKdLF9IMeta } from "/home/runner/work/rediredi-storefront/rediredi-storefront/pages/produtos/[id]/[[title]].vue?macro=true";
import { default as indexHJJfOsnWMSMeta } from "/home/runner/work/rediredi-storefront/rediredi-storefront/pages/produtos/index.vue?macro=true";
import { default as _91salesperson_93P0ZhrDX1HOMeta } from "/home/runner/work/rediredi-storefront/rediredi-storefront/pages/s/[salesperson].vue?macro=true";
export default [
  {
    name: indexBNnOS4J1c3Meta?.name ?? "about",
    path: indexBNnOS4J1c3Meta?.path ?? "/about",
    meta: indexBNnOS4J1c3Meta || {},
    alias: indexBNnOS4J1c3Meta?.alias || [],
    redirect: indexBNnOS4J1c3Meta?.redirect || undefined,
    component: () => import("/home/runner/work/rediredi-storefront/rediredi-storefront/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: index8tzUv43YSwMeta?.name ?? "checkout",
    path: index8tzUv43YSwMeta?.path ?? "/checkout",
    meta: index8tzUv43YSwMeta || {},
    alias: index8tzUv43YSwMeta?.alias || [],
    redirect: index8tzUv43YSwMeta?.redirect || undefined,
    component: () => import("/home/runner/work/rediredi-storefront/rediredi-storefront/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93oziuXCTLdGMeta?.name ?? "checkout-success-identifier-id",
    path: _91id_93oziuXCTLdGMeta?.path ?? "/checkout/success/:identifier()/:id()",
    meta: _91id_93oziuXCTLdGMeta || {},
    alias: _91id_93oziuXCTLdGMeta?.alias || [],
    redirect: _91id_93oziuXCTLdGMeta?.redirect || undefined,
    component: () => import("/home/runner/work/rediredi-storefront/rediredi-storefront/pages/checkout/success/[identifier]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexzKdeOdJNCkMeta?.name ?? "index",
    path: indexzKdeOdJNCkMeta?.path ?? "/",
    meta: indexzKdeOdJNCkMeta || {},
    alias: indexzKdeOdJNCkMeta?.alias || [],
    redirect: indexzKdeOdJNCkMeta?.redirect || undefined,
    component: () => import("/home/runner/work/rediredi-storefront/rediredi-storefront/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexYP28gpEmqjMeta?.name ?? "not-available",
    path: indexYP28gpEmqjMeta?.path ?? "/not-available",
    meta: indexYP28gpEmqjMeta || {},
    alias: indexYP28gpEmqjMeta?.alias || [],
    redirect: indexYP28gpEmqjMeta?.redirect || undefined,
    component: () => import("/home/runner/work/rediredi-storefront/rediredi-storefront/pages/not-available/index.vue").then(m => m.default || m)
  },
  {
    name: _91token_933iprqfPdpkMeta?.name ?? "orders-token",
    path: _91token_933iprqfPdpkMeta?.path ?? "/orders/:token()",
    meta: _91token_933iprqfPdpkMeta || {},
    alias: _91token_933iprqfPdpkMeta?.alias || [],
    redirect: _91token_933iprqfPdpkMeta?.redirect || undefined,
    component: () => import("/home/runner/work/rediredi-storefront/rediredi-storefront/pages/orders/[token].vue").then(m => m.default || m)
  },
  {
    name: _91_91title_93_93q7Qokm9tqQMeta?.name ?? "products-id-title",
    path: _91_91title_93_93q7Qokm9tqQMeta?.path ?? "/products/:id()/:title?",
    meta: _91_91title_93_93q7Qokm9tqQMeta || {},
    alias: _91_91title_93_93q7Qokm9tqQMeta?.alias || [],
    redirect: _91_91title_93_93q7Qokm9tqQMeta?.redirect || undefined,
    component: () => import("/home/runner/work/rediredi-storefront/rediredi-storefront/pages/products/[id]/[[title]].vue").then(m => m.default || m)
  },
  {
    name: indexFxylc02rcBMeta?.name ?? "products",
    path: indexFxylc02rcBMeta?.path ?? "/products",
    meta: indexFxylc02rcBMeta || {},
    alias: indexFxylc02rcBMeta?.alias || [],
    redirect: indexFxylc02rcBMeta?.redirect || undefined,
    component: () => import("/home/runner/work/rediredi-storefront/rediredi-storefront/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91title_93_93QA9sKdLF9IMeta?.name ?? "produtos-id-title",
    path: _91_91title_93_93QA9sKdLF9IMeta?.path ?? "/produtos/:id()/:title?",
    meta: _91_91title_93_93QA9sKdLF9IMeta || {},
    alias: _91_91title_93_93QA9sKdLF9IMeta?.alias || [],
    redirect: _91_91title_93_93QA9sKdLF9IMeta?.redirect || undefined,
    component: () => import("/home/runner/work/rediredi-storefront/rediredi-storefront/pages/produtos/[id]/[[title]].vue").then(m => m.default || m)
  },
  {
    name: indexHJJfOsnWMSMeta?.name ?? "produtos",
    path: indexHJJfOsnWMSMeta?.path ?? "/produtos",
    meta: indexHJJfOsnWMSMeta || {},
    alias: indexHJJfOsnWMSMeta?.alias || [],
    redirect: indexHJJfOsnWMSMeta?.redirect || undefined,
    component: () => import("/home/runner/work/rediredi-storefront/rediredi-storefront/pages/produtos/index.vue").then(m => m.default || m)
  },
  {
    name: _91salesperson_93P0ZhrDX1HOMeta?.name ?? "s-salesperson",
    path: _91salesperson_93P0ZhrDX1HOMeta?.path ?? "/s/:salesperson()",
    meta: _91salesperson_93P0ZhrDX1HOMeta || {},
    alias: _91salesperson_93P0ZhrDX1HOMeta?.alias || [],
    redirect: _91salesperson_93P0ZhrDX1HOMeta?.redirect || undefined,
    component: () => import("/home/runner/work/rediredi-storefront/rediredi-storefront/pages/s/[salesperson].vue").then(m => m.default || m)
  }
]