<script setup lang="ts">
import { useDebounceFn } from '@vueuse/core'

const emit = defineEmits(['fetch'])

const searchInputRef = ref()
const itemsStore = useItemsStore()
const { event, metaEvent } = useEvent()

const blur = () => {
  searchInputRef.value.el.blur()
}
const debouncedUpdate = useDebounceFn((val: string) => {
  itemsStore.clearOnSearch()
  itemsStore.facetBy = null
  itemsStore.term = undefined
  itemsStore.query = val || undefined
  event('search', {
    search_term: val,
  })
  metaEvent('Search', { search_string: val })
  emit('fetch')
}, 500)

const clear = () => {
  itemsStore.clearOnSearch()
  itemsStore.query = undefined
  emit('fetch')
}
</script>

<template>
  <div data-testid="product-filters">
    <div
      class="m-auto mb-0 flex w-full flex-col gap-4 sm:mb-6 md:gap-6"
      :class="{ 'mb-4 md:mb-6': !itemsStore.facets.length && !itemsStore.hasPromotions }"
    >
      <div
        v-if="itemsStore.totalRecords && itemsStore.totalRecords > 0"
        class="min-w-full self-center md:min-w-[552px]"
      >
        <RInput
          ref="searchInputRef"
          :model-value="itemsStore.query || ''"
          :input-props="{
            type: 'search',
            enterKeyHint: 'go',
            placeholder: $t('globals.search'),
            class: 'search-input',
          }"
          clearable
          @enter-press="blur"
          @update:model-value="debouncedUpdate"
          @clear="clear"
        >
          <template #appended>
            <RIcon size="26" name="ic:baseline-search" />
          </template>
        </RInput>
      </div>

      <RProductFacets data-testid="facets" @fetch="emit('fetch')" />
    </div>
  </div>
</template>
