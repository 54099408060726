import { IPhone } from '@rediredi/types'

const WHATSAPP_LINK = 'https://wa.me/'

export const useWhatsappMessage = () => {
  const currentStore = useCurrentStore()
  const { event, metaEvent } = useEvent()
  const currency = currentStore?.currency
  const phone = currentStore?.whatsappPhoneNumber as IPhone
  const number = phone ? (phone.countryCode + phone.number).replace(/\D/gim, '') : ''

  const send = (text: string) => {
    if (!number) {
      throw new Error('This store has no phone number configured')
    }

    const qs = new URLSearchParams({
      text,
    })

    window.open(`${WHATSAPP_LINK}${number}?${qs}`, '_blank')

    event('generate_lead', {
      currency,
      value: undefined,
    })

    metaEvent('Lead', MetaEventsPayloads.lead())
  }

  return {
    send,
  }
}
